<template>
  <div id="modalUpdateDateSchedule" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Atualizar Data e Hora</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="updateSchedule">
          <div class="modal-body pb-1">
            <div class="row">
              <div class="col-12">
                <h6 class="text-center">Selecione uma Cidade</h6>
              </div>
              <div
                v-for="city in cities"
                :key="city.code"
                class="d-flex col-lg-4"
                @click="setCity(city)"
              >
                <div
                  class="card card-body cursor-pointer"
                  :class="
                    selectedCity.id == city.id ? 'bg-success text-white' : ''
                  "
                >
                  <p class="mb-0">{{ city.name }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="text-center">Selecione uma Unidade</h6>
              </div>
              <div
                v-for="unity in unities"
                :key="unity.code"
                class="d-flex col-lg-4"
                @click="setUnity(unity)"
              >
                <div
                  class="card card-body cursor-pointer"
                  :class="
                    selectedUnity.code == unity.code
                      ? 'bg-success text-white'
                      : ''
                  "
                >
                  <p class="mb-0">{{ unity.name }}</p>
                  <p class="mb-0">
                    <small>{{ unity.address }}</small>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="isSelectedUnity" class="row mb-4">
              <div class="col-12">
                <h6 class="text-center">Selecione uma Data e Hora</h6>
                <div v-if="loadData">
                  <LoadingData />
                </div>
                <div v-else>
                  <div class="row" v-if="dateTimeAvaliable.length === 0">
                    <div class="col-12">
                      <p>Nenhum horário disponível, selecione outra agenda.</p>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-3 list-date">
                      <div
                        v-for="(day, index) in avaliableDates"
                        :key="index"
                        class="w-100"
                      >
                        <input
                          type="radio"
                          class="btn-check"
                          :id="`option_date_${index}`"
                          name="selectedDate"
                          :value="day"
                          v-model="selectedDate"
                        />
                        <label
                          class="btn my-1 w-100"
                          :class="
                            selectedDate == day ? 'btn-success' : 'btn-light'
                          "
                          :for="`option_date_${index}`"
                        >
                          {{ day }}
                        </label>
                      </div>
                    </div>
                    <div class="col-9 list-time">
                      <div class="row">
                        <div
                          v-for="(time, index) in avaliableTimes"
                          :key="index"
                          class="col-2"
                        >
                          <input
                            type="radio"
                            class="btn-check"
                            :id="`option_time_${index}`"
                            name="selectedTime"
                            :value="time.horario"
                            v-model="selectedTime"
                          />
                          <label
                            class="btn my-1 w-100"
                            :class="
                              selectedTime == time.horario
                                ? 'btn-success'
                                : 'btn-light'
                            "
                            :for="`option_time_${index}`"
                          >
                            {{ time.horario }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link text-secondary"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-secondary"
              title="Atualizar"
              :disabled="disabledButton"
            >
              Atualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import { dateIncreaseDay } from '@/utils/date'
import { showModal, hideModal } from '@/utils/modal'
import cityEnum from '@/enum/cityEnum'

export default {
  name: 'UpdateDateScheduleModal',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
  },

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      disabledButton: true,
      schedule: {},
      cities: [],
      unities: [],
      selectedCity: {
        id: null,
      },
      selectedUnity: {
        code: null,
      },
      isSelectedUnity: false,
      dateTimeAvaliable: [],
      avaliableDates: [],
      avaliableTimes: [],
      selectedDate: null,
      selectedTime: null,
      selectedDateTime: null,
    }
  },

  watch: {
    selectedUnity: {
      handler(newVal, oldVal) {
        if (newVal.code !== null) {
          this.isSelectedUnity = true
          this.getAvaliableTimes()
        } else {
          this.isSelectedUnity = false
        }
      },
      immediate: true,
    },
    selectedDate: {
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.selectedTime = null
          this.showTimeList()
        }
      },
      immediate: true,
    },
    selectedTime: {
      handler(newVal, oldVal) {
        if (newVal !== null) {
          this.selectedDateTime = `${this.selectedDate} ${this.selectedTime}`
          this.disabledButton = false
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getSchedule() {
      try {
        const response = await this.$store.dispatch('getSchedule', {
          uuid: this.uuid,
        })
        this.schedule = response.schedule
        this.getCities()
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getCities() {
      try {
        const cities = await this.$store.dispatch('getCities')

        this.cities = cities.filter(
          (city) => city.id !== cityEnum.OUTRAS_LOCALIDADES,
        )

        this.selectedCity = this.cities.find(
          (city) => city.id === this.schedule.cityId,
        )

        this.unities = this.selectedCity.unities
        showModal('modalUpdateDateSchedule')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setCity(city) {
      this.selectedCity = city
      this.selectedUnity = {
        code: null,
      }
      this.unities = this.selectedCity.unities
    },

    setUnity(unity) {
      this.selectedUnity = unity
    },

    async getAvaliableTimes() {
      try {
        this.loadData = true
        this.avaliableDates = []
        this.avaliableTimes = []

        this.dateTimeAvaliable = await this.$store.dispatch(
          'getAvaliableTimes',
          {
            companyCode: this.schedule.companyCode,
            unityCode: this.selectedUnity.code,
            startDate: dateIncreaseDay(1),
            endDate: dateIncreaseDay(7),
          },
        )

        this.avaliableDates = this.dateTimeAvaliable.map(function (date) {
          return date.data
        })

        this.selectedDate = null
        this.selectedTime = null

        this.avaliableDates = [...new Set(this.avaliableDates)]

        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    showTimeList() {
      this.avaliableTimes = this.dateTimeAvaliable.filter(
        (date) => date.data === this.selectedDate,
      )
      this.avaliableTimes = [...new Set(this.avaliableTimes)]
    },

    async updateSchedule() {
      this.disabledButton = true
      try {
        const response = await this.$store.dispatch('updateSchedule', {
          uuid: this.uuid,
          form: {
            user: this.user,
            city: {
              id: this.selectedCity.id,
              name: this.selectedCity.name,
            },
            unity: {
              code: this.selectedUnity.code,
            },
            dateTime: this.selectedDateTime,
          },
        })

        hideModal()
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(response.message)
      } catch (error) {
        this.disabledButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getSchedule()
  },
}
</script>

<style scoped>
.list-date {
  max-height: 500px;
  overflow-y: auto;
}

.list-time {
  max-height: 350px;
  overflow-y: auto;
}
</style>
