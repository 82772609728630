<template>
  <div id="modalCancelSchedule" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cancelar Agendamento</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="cancelSchedule">
          <div class="modal-body pb-1">
            Tem certeza que deseja cancelar o agendamento?
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-link text-secondary"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Confirmar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Confirmar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'CancelScheduleModal',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      disableButton: false,
    }
  },

  methods: {
    async cancelSchedule() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('cancelSchedule', {
          uuid: this.uuid,
          user: this.user,
        })

        hideModal()
        this.$emit('closeModal', true)
        this.$root.$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalCancelSchedule')
  },
}
</script>
