<template>
  <UpdateDateScheduleModal
    v-if="showUpdateDateScheduleModal"
    :uuid="schedule.schedule.uuid"
    :user="schedule.schedule.collaboratorName"
    @closeModal="closeModal"
  />
  <SelectSuggestDateScheduleModal
    v-if="showSelectSuggestDateScheduleModal"
    :uuid="schedule.schedule.uuid"
    :user="schedule.schedule.collaboratorName"
    @closeModal="closeModal"
  />
  <CancelScheduleModal
    v-if="showCancelScheduleModal"
    :uuid="schedule.schedule.uuid"
    :user="schedule.schedule.collaboratorName"
    @closeModal="closeModal"
  />
  <div class="content">
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">Detalhes do Agendamento</h4>
        <div class="ms-auto">
          <router-link to="/" class="btn btn-outline-dark" title="Sair">
            Sair
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h6>Dados do Funcionário:</h6>
          </div>
          <div class="col-12">
            <p>
              <strong>Nome:</strong> {{ schedule.schedule.collaboratorName }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p><strong>CPF:</strong> {{ schedule.schedule.collaboratorCpf }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Código:</strong> {{ schedule.schedule.collaboratorCode }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Formas de Contato:</h6>
          </div>
          <div
            v-for="contact in schedule.contactMethod"
            :key="contact.id"
            class="col-12 col-md-6"
          >
            <p>
              <strong>{{ contact.name }}:</strong> {{ contact.value }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Dados do Agendamento:</h6>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Tipo de Exame:</strong>
              {{ schedule.schedule.typeOfExamName }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Status: </strong>
              <span
                v-html="
                  statusLabel(
                    schedule.schedule.calendarStatus,
                    schedule.schedule.status,
                  )
                "
              />
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              <strong>Cidade:</strong>
              {{ schedule.schedule.cityName }}
            </p>
          </div>
          <div v-if="schedule.schedule.preferredRegion" class="col-12 col-md-6">
            <p>
              <strong>Região de Preferência:</strong>
              {{ schedule.schedule.preferredRegion }}
            </p>
          </div>
          <div v-if="schedule.schedule.unityName" class="col-12 col-md-6">
            <p>
              <strong>Unidade:</strong> {{ schedule.schedule.unityName }} ({{
                schedule.schedule.unityAddress
              }})
            </p>
          </div>
          <div v-if="schedule.schedule.providerName" class="col-12 col-md-12">
            <p>
              <strong>Clínica Parceira:</strong>
              {{ schedule.schedule.providerName }} ({{
                schedule.schedule.providerAddress
              }})
            </p>
          </div>
          <div v-if="schedule.schedule.date" class="col-12 col-md-6">
            <p>
              <strong>Data:</strong>
              {{
                schedule.schedule.date
                  ? dateTimeSqlToDateBR(schedule.schedule.date) +
                    ' ' +
                    schedule.schedule.time
                  : 'Não Selecionada'
              }}
            </p>
          </div>
          <div
            v-if="schedule.schedule.cityId == cityEnum.OUTRAS_LOCALIDADES"
            class="col-12"
          >
            <strong>Sugestões de Data:</strong>
            <ul class="mt-1">
              <li
                v-for="dataSuggestion in schedule.dataSuggestion"
                :key="dataSuggestion.id"
              >
                {{
                  dateTimeSqlToDateTimeBR(
                    `${dataSuggestion.date} ${dataSuggestion.time}`,
                  )
                }}
              </li>
            </ul>
          </div>
          <div v-if="schedule.schedule.observation" class="col-12">
            <p>
              <strong>Observações:</strong> {{ schedule.schedule.observation }}
            </p>
          </div>
          <div v-if="schedule.schedule.canceledReason" class="col-12">
            <p>
              <strong>Motivo do Cancelamento:</strong>
              {{ schedule.schedule.canceledReason }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h6>Lista de Exames:</h6>
          </div>
          <div v-if="schedule.exams" class="col-12">
            <ul v-if="schedule.exams.length > 0" class="mb-0">
              <li v-for="exam in schedule.exams" :key="exam.codigoExame">
                {{ exam.nomeExame }}
              </li>
            </ul>
            <ul v-else class="mb-0">
              <li>Nenhum exame encontrado</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button
          v-if="canCancelSchedule"
          type="button"
          class="btn btn-danger"
          title="Cancelar Agendamento"
          @click="showCancelScheduleModal = true"
        >
          Cancelar
        </button>
        <button
          v-if="canEditSchedule"
          type="button"
          class="btn btn-warning ms-2"
          title="Selecionar Data e Hora do Agendamento"
          @click="showUpdateDateScheduleModal = true"
        >
          Selecionar Data/Hora
        </button>
        <button
          v-if="canSelectSuggestDateSchedule"
          type="button"
          class="btn btn-warning ms-2"
          title="Indicar Data/Hora desejada"
          @click="showSelectSuggestDateScheduleModal = true"
        >
          Indicar Data/Hora Desejada
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import UpdateDateScheduleModal from '@/components/commons/schedules/UpdateDateScheduleModal.vue'
import SelectSuggestDateScheduleModal from '@/components/collaborators/schedules/SelectSuggestDateScheduleModal.vue'
import CancelScheduleModal from '@/components/commons/schedules/CancelScheduleModal.vue'
import { mapGetters } from 'vuex'
import { statusLabel } from '@/utils/strings'
import { dateTimeSqlToDateTimeBR, dateTimeSqlToDateBR } from '@/utils/date'
import cityEnum from '@/enum/cityEnum'
import calendarStatusEnum from '@/enum/calendarStatusEnum'
import typeScheduleEnum from '@/enum/typeScheduleEnum'

export default {
  name: 'DetailsSchedule',

  components: {
    LoadingData,
    UpdateDateScheduleModal,
    SelectSuggestDateScheduleModal,
    CancelScheduleModal,
  },

  data() {
    return {
      key: this.$route.params.key,
      loadData: true,
      showUpdateDateScheduleModal: false,
      showSelectSuggestDateScheduleModal: false,
      showCancelScheduleModal: false,
      schedule: {},
      cityEnum,
      statusLabel,
      dateTimeSqlToDateTimeBR,
      dateTimeSqlToDateBR,
    }
  },

  computed: {
    ...mapGetters(['companyCode']),

    canCancelSchedule() {
      if (
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.CANCELADO ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.COMPARECEU ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.NAO_COMPARECEU
      ) {
        return false
      }

      if (this.schedule.schedule.date) {
        const dateTimeNow = new Date()
        const dateTimeSchedule = new Date(
          `${this.schedule.schedule.date} ${this.schedule.schedule.time}`,
        )
        const dateTimeLimite = new Date(
          dateTimeSchedule.getTime() - 1000 * 60 * 60 * 24,
        )

        if (dateTimeLimite < dateTimeNow) {
          return false
        }
      }
      return true
    },

    canEditSchedule() {
      if (
        this.schedule.schedule.calendarType === typeScheduleEnum.DIRETO ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.CANCELADO ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.COMPARECEU ||
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.NAO_COMPARECEU ||
        this.schedule.schedule.cityId === cityEnum.OUTRAS_LOCALIDADES
      ) {
        return false
      }

      if (this.schedule.schedule.date) {
        const dateTimeNow = new Date()
        const dateTimeSchedule = new Date(
          `${this.schedule.schedule.date} ${this.schedule.schedule.time}`,
        )
        const dateTimeLimite = new Date(
          dateTimeSchedule.getTime() - 1000 * 60 * 60 * 24,
        )

        if (dateTimeLimite < dateTimeNow) {
          return false
        }
      }
      return true
    },

    canSelectSuggestDateSchedule() {
      if (
        this.schedule.schedule.calendarStatus ===
          calendarStatusEnum.AGUARDANDO_COLABORADOR &&
        this.schedule.schedule.cityId === cityEnum.OUTRAS_LOCALIDADES &&
        this.schedule.dataSuggestion.length === 0
      ) {
        return true
      }
      return false
    },
  },

  methods: {
    async getSchedule() {
      try {
        this.schedule = await this.$store.dispatch('getSchedule', {
          uuid: this.key,
        })

        this.schedule.exams = await this.$store.dispatch(
          'getExamsByCollaborator',
          {
            companyCode: this.schedule.schedule.companyCode,
            collaboratorCode: this.schedule.schedule.collaboratorCode,
            typeOfExam: this.schedule.schedule.typeOfExamType,
          },
        )

        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$router.push({
          name: 'CollaboratorAuth',
        })
      }
    },

    closeModal(reloadData) {
      if (reloadData) {
        this.getSchedule()
      }
      this.showUpdateDateScheduleModal = false
      this.showSelectSuggestDateScheduleModal = false
      this.showCancelScheduleModal = false
    },
  },

  mounted() {
    this.getSchedule()
  },
}
</script>
